import {
	createContext,
	useContext,
	FC,
	useState,
	useEffect,
	useCallback,
} from "react";

import { ErdstallSession } from "@polycrypt/erdstall";
import { TxReceipt } from "@polycrypt/erdstall/api/responses";
import { Assets, Amount } from "@polycrypt/erdstall/ledger/assets";
import { Address } from "@polycrypt/erdstall/ledger";

import { utils, providers } from "ethers";

interface UserData {
	name: string;
	pictureId: number;
	premium: boolean;
}

interface User {
	userData?: UserData;
	initializeUserData: (userData: UserData) => void;
	provider?: providers.Provider;
	setProvider: (provider: providers.Provider) => void;
	session?: ErdstallSession;
	setSession: (session: ErdstallSession) => void;
	prnBalance: number;
}

const UserContext = createContext<User>({} as User);

const UserProvider: FC<{}> = (props) => {
	const [userData, setUserData] = useState<UserData>();
	const [session, setSession] = useState<ErdstallSession>();
	const [provider, setProvider] = useState<providers.Provider>();
	const [prnBalance, setPrnBalance] = useState<number>(0);

	const initializeUserData = useCallback(
		(userData: UserData) => {
			setUserData(userData);
		},
		[setUserData],
	);

	useEffect(() => {
		if (!session) return;
		session
			.getOwnAccount()
			.then((account) => {
				setPrnBalance(getPrnAmount(account.values));
			})
			.catch((error) => console.error(error));
		session.on("receipt", (receipt: TxReceipt) => {
			const acc = receipt.delta.get(session.address.key);
			if (acc) setPrnBalance(getPrnAmount(acc.values));
		});
	}, [session]);

	return (
		<UserContext.Provider
			value={{
				userData,
				initializeUserData,
				provider,
				setProvider,
				session,
				setSession,
				prnBalance,
			}}
			{...props}
		/>
	);
};

function getPrnAmount(assets: Assets): number {
	// Workaround: return the first ERC20 token we can find.
	// FIXME: add proper querying of PRN token.
	for (const [addr, asset] of assets.values.entries())
		if (!Address.fromString(addr).isZero() && asset instanceof Amount)
			return Number(utils.formatEther((asset as Amount).value));
	return 0;
}

const useUserContext = () => useContext(UserContext);

export default useUserContext;
export { UserProvider, useUserContext };
