export type NetworkName =
	| "Ropsten"
	| "Rinkeby"
	| "Goerli"
	| "Kovan"
	| "localhost";

export const NetworkID = new Map<number, NetworkName>([
	[3, "Ropsten"],
	[4, "Rinkeby"],
	[5, "Goerli"],
	[42, "Kovan"],
	[1337, "localhost"],
]);
