import { useContext, useState, useEffect } from "react";

import { Address } from "@polycrypt/erdstall/ledger";

import { NERDContext } from "../AppContext";
import useUserContext from "../context/UserContext";
import { NetworkID, NetworkName } from "../logic/networks";
import { ResolveTokenNameAndSymbol } from "../logic/utils";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

interface props {
	assets: [Address, string][];
}

export default function WithdrawSummary(props: props) {
	const { session } = useUserContext();
	const { config } = useContext(NERDContext);

	if (!session) {
		return <Typography>ould not connect to your account.</Typography>;
	}
	if (!config) {
		return <Typography>Could not find the network.</Typography>;
	}

	const networkName = NetworkID.get(Number(config.networkID))!;
	const entries = props.assets.map(([token, amount]) => {
		return (
			<WithdrawEntry
				key={token.toString()}
				token={token}
				amount={amount}
				link={mkEtherscanLink(networkName, token, session.address)}
			/>
		);
	});
	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Link</TableCell>
						<TableCell>Amount</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{entries}</TableBody>
			</Table>
		</TableContainer>
	);
}

interface entryProps {
	token: Address;
	amount: string;
	link: string;
}

function WithdrawEntry(props: entryProps) {
	const { provider } = useUserContext();
	const [name, setName] = useState<string | Address>(props.token);
	const [sym, setSym] = useState<string>();

	const fetchNameAndSym = () => {
		if (!provider) return;

		ResolveTokenNameAndSymbol(provider, props.token).then(([n, s]) => {
			setName(n);
			setSym(s);
		});
	};

	useEffect(fetchNameAndSym, [props, provider]);

	return (
		<TableRow>
			<TableCell>
				<a
					href={props.link}
					target="_blank"
					rel="noreferrer"
				>{`${name}`}</a>
				:
			</TableCell>
			<TableCell>
				{props.amount} {sym ? sym : ""}
			</TableCell>
		</TableRow>
	);
}

function mkEtherscanLink(
	network: NetworkName,
	token: Address,
	owner: Address,
): string {
	return `https://${network.toLowerCase()}.etherscan.io/token/${token}?a=${owner}`;
}
