import { useCallback, useEffect, useRef, useState } from "react";

import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemText,
	Paper,
	TextField,
	Typography,
} from "@mui/material";

import { utils } from "ethers";
import { useHistory } from "react-router-dom";

import { Assets, Tokens } from "@polycrypt/erdstall/ledger/assets";
import { Address } from "@polycrypt/erdstall/ledger";

import useUserContext from "../context/UserContext";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/system";
import {
	Erdstall,
	Erdstall__factory,
} from "@polycrypt/erdstall/ledger/backend";

interface DepositDialogProps {
	open: boolean;
	handleClose: () => void;
}

export function DepositDialog({ open, handleClose }: DepositDialogProps) {
	const { session } = useUserContext();
	const history = useHistory();

	let [assets, setAssets] = useState(new Assets());
	let [depositing, setDepositing] = useState(false);

	const [depositStage, setDepositStage] = useState(0);
	const [depositStageName, setDepositStageName] = useState<string>();

	const depositFunds = async (): Promise<void> => {
		setDepositing(true);
		const setStage = (i: number, name: string) => {
			setDepositStage(i);
			setDepositStageName(name);
		};

		let stage = 0;
		setStage(++stage, "Preparing deposit transaction");
		const { stages } = await session!.deposit(assets);

		let i = 0;
		for await (const [name, tx] of stages) {
			setStage(++stage, `Awaiting transaction ${++i}: ${name}`);
			await tx.wait();
		}

		setAssets(new Assets());
	};

	const clearEntries = () => {
		setAssets(new Assets());
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
			<DialogTitle>Deposit</DialogTitle>
			<DialogContent>
				<DepositForm assets={assets} setAssets={setAssets} />
				{depositing && (
					<>
						<LinearProgress />
						<Typography>
							{depositStage}: {depositStageName}
						</Typography>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => {
						depositFunds()
							.finally(() => setDepositing(false))
							.then(() => {
								handleClose();
								history.push("/me/nfts");
								alert(
									"Your deposit was successful but it may take some minutes until your NFTs are available within NERD.",
								);
							})
							.catch(() => alert("Deposit failed"));
					}}
					disabled={depositing}
				>
					deposit
				</Button>
				<Button
					variant="contained"
					color="warning"
					onClick={clearEntries}
				>
					clear
				</Button>
				<Button onClick={handleClose}>cancel</Button>
			</DialogActions>
		</Dialog>
	);
}

interface DepositFormProps {
	assets: Assets;
	setAssets: (assets: Assets) => void;
}

function DepositForm(props: DepositFormProps) {
	const { session, provider } = useUserContext();

	const [presentAddresses, setPresentAddresses] = useState<string[]>([]);
	const [availableTokens, setAvailableTokens] = useState<string[]>([]);

	const erdstall = useRef<Erdstall>(
		Erdstall__factory.connect(session!.erdstall().toString(), provider!),
	);

	const [editAddr, setEditAddr] = useState("");
	const isERC721 = useCallback(
		async (address: string): Promise<boolean> => {
			return ["ERC721", "ERC721Mintable"].includes(
				await session!.tokenProvider.tokenTypeOf(
					erdstall.current,
					address,
				),
			);
		},
		[session, erdstall],
	);
	const isValidAddr = useCallback(async (): Promise<boolean> => {
		return utils.isAddress(editAddr) && isERC721(editAddr);
	}, [editAddr, isERC721]);

	const [editID, setEditID] = useState("");

	const [isValid, setIsValid] = useState<boolean>(true);

	useEffect(() => {
		console.log("fired");
		const isValidID = (): boolean => /^\d+$/.test(editID);
		const checkValid = async (): Promise<boolean> =>
			(await isValidAddr()) && isValidID();

		checkValid()
			.then(setIsValid)
			.then(() => console.log(isValid));
	}, [editAddr, editID, isValidAddr, isValid]);

	useEffect(() => {
		session!.tokenProvider
			.queryRegisteredTokens(erdstall.current)
			.then((registered) => registered.map((x) => x.token.toString()))
			.then(async (registered) => {
				const flags = await Promise.all(registered.map(isERC721));
				return registered.filter((_, i) => flags[i]);
			})
			.then(setPresentAddresses);
	}, [session, isERC721, setPresentAddresses]);

	useEffect(() => {
		if (!isValidAddr()) return;
		session!.onChainQuerier
			.queryTokensOwnedByAddress(editAddr, session!.address.toString())
			.then((tokens) => tokens.value.map((x) => x.toString()))
			.then(setAvailableTokens);
	}, [session, editAddr, isValidAddr]);

	const assets: [Address, Tokens][] = [];
	for (const [addr, asset] of props.assets.values) {
		assets.push([Address.fromString(addr), asset as Tokens]);
	}

	const addEntry = () => {
		const [addr, id] = [Address.fromString(editAddr), BigInt(editID)];
		const asset = new Tokens([id]);
		const onlyAsset = new Assets({ token: addr, asset });

		if (["eq", "lt"].includes(onlyAsset.cmp(props.assets))) {
			return;
		}
		const clone = new Assets(
			...assets.map(([token, asset]) => ({ token, asset })),
		);
		clone.addAsset(addr, asset);
		props.setAssets(clone);
	};

	return (
		<Grid container spacing={1} alignItems="center">
			<Grid item xs={5}>
				<Autocomplete
					id="contract"
					options={presentAddresses}
					freeSolo
					onChange={(event, newValue) => {
						setEditAddr(newValue || "");
					}}
					renderInput={(params) => (
						<TextField
							margin="dense"
							label="Contract"
							variant="standard"
							color="secondary"
							value={editAddr}
							error={!isValid}
							type="string"
							onChange={(event) => {
								setEditAddr(event.target.value);
							}}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={5}>
				<Autocomplete
					id="id"
					options={availableTokens}
					freeSolo
					onChange={(event, newValue) => {
						setEditID(newValue || "");
					}}
					renderInput={(params) => (
						<TextField
							margin="dense"
							label="ID"
							variant="standard"
							color="secondary"
							value={editID}
							error={!isValid}
							type="string"
							onChange={(event) => {
								setEditID(event.target.value);
							}}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={2}>
				<IconButton
					color="secondary"
					onClick={addEntry}
					disabled={!isValid}
				>
					<Add />
				</IconButton>
			</Grid>
			{assets.map(([address, tokens]) => (
				<Grid item xs={12} key={address.toString()}>
					<Paper variant="outlined">
						<Typography variant="h6" textAlign="center">
							{address.toString()}
						</Typography>
						<Box m="auto">
							<List sx={{ p: 0, my: "auto" }}>
								{tokens.value.map((id) => (
									<ListItem key={id.toString()}>
										<ListItemText>
											{id.toString()}
										</ListItemText>
									</ListItem>
								))}
							</List>
						</Box>
					</Paper>
				</Grid>
			))}
		</Grid>
	);
}
