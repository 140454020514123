// SPDX-License-Identifier: Apache-2.0

import { Asset, Tokens } from "@polycrypt/erdstall/ledger/assets";
import { Address } from "@polycrypt/erdstall/ledger";

export function extractNfts(
	vs: Map<string, Asset>, // usually Assets.values
): { token: Address; id: bigint }[] {
	const nfts: { token: Address; id: bigint }[] = [];
	for (const [tokenStr, value] of vs) {
		const token = Address.fromString(tokenStr);
		if (value instanceof Tokens) {
			nfts.push(
				...value.value.map((id) => {
					return { token: token, id: id };
				}),
			);
		}
	}
	return nfts;
}
