import { Refresh } from "@mui/icons-material";
import {
	Card,
	CardMedia,
	CardContent,
	Button,
	TextField,
	FormControlLabel,
	Checkbox,
	CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getPicsumUrl, randomPicsumId } from "../logic/utils";

interface MetadataFormProps {
	mediaHeight: number;
	imageId: number;
	setImageId: React.Dispatch<React.SetStateAction<number>>;
	isConfidential: boolean;
	setIsConfidential: React.Dispatch<React.SetStateAction<boolean>>;
	titleInput: string;
	setTitleInput: React.Dispatch<React.SetStateAction<string>>;
	descriptionInput: string;
	setDescriptionInput: React.Dispatch<React.SetStateAction<string>>;
}

export const TITLE_LIMIT = 128;
export const DESCRIPTION_LIMIT = 1024;

export default function MetadataForm({
	imageId,
	setImageId,
	mediaHeight,
	isConfidential,
	setIsConfidential,
	titleInput,
	setTitleInput,
	descriptionInput,
	setDescriptionInput,
}: MetadataFormProps) {
	const [imageLoading, setImageLoading] = useState<boolean>(false);

	useEffect(() => {
		setImageLoading(true);
		randomPicsumId()
			.then(setImageId)
			.then(() => setImageLoading(false));
	}, [setImageId]);

	return (
		<Card>
			<CardMedia
				component="img"
				src={getPicsumUrl(imageId, mediaHeight, false)}
				height={mediaHeight}
				alt={`PICSUM ${imageId}`}
			/>
			<CardContent
				sx={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<Button
					startIcon={<Refresh />}
					endIcon={imageLoading && <CircularProgress size={20} />}
					variant="contained"
					color="secondary"
					onClick={() => {
						setImageLoading(true);
						randomPicsumId()
							.then(setImageId)
							.then(() => setImageLoading(false));
					}}
				>
					new image
				</Button>
				<TextField
					margin="dense"
					id="title"
					label="Title"
					fullWidth
					variant="filled"
					color="secondary"
					onChange={(event) => {
						setTitleInput(event.target.value);
					}}
					error={titleInput.length > TITLE_LIMIT}
					value={titleInput}
					helperText={
						titleInput.length > TITLE_LIMIT
							? "This title is too long"
							: "Enter the NFT's title"
					}
				/>
				<TextField
					margin="dense"
					id="description"
					label="Description"
					fullWidth
					variant="filled"
					color="secondary"
					onChange={(event) => {
						setDescriptionInput(event.target.value);
					}}
					error={descriptionInput.length > DESCRIPTION_LIMIT}
					value={descriptionInput}
					helperText={
						descriptionInput.length > DESCRIPTION_LIMIT
							? "This description is too long"
							: "Enter the NFT's description"
					}
				/>
				<FormControlLabel
					label="Mark as confidential"
					id="confidential"
					control={
						<Checkbox
							checked={isConfidential}
							onChange={() =>
								setIsConfidential((previous) => !previous)
							}
						/>
					}
				/>
			</CardContent>
		</Card>
	);
}
