import { useContext, useState } from "react";
import {
	Button,
	Card,
	CardContent,
	Container,
	Grid,
	Typography,
	CardActions,
} from "@mui/material";
import { useHistory } from "react-router";
import useUserContext from "../context/UserContext";
import { NERDContext } from "../AppContext";
import { Mint } from "@polycrypt/erdstall/api/transactions";
import { PerunArtNFT } from "@polycrypt/nerd-marketplace/src/server/nft";
import useNerdBackendContext from "../context/NerdBackendContext";
import { PerunArtMetadata } from "@polycrypt/nerd-marketplace/src/nft";
import { getPicsumUrl } from "../logic/utils";
import MetadataForm, {
	TITLE_LIMIT,
	DESCRIPTION_LIMIT,
} from "../components/MetadataForm";

const CARD_MEDIA_HEIGHT = 400;

export default function MintNft() {
	const { session } = useUserContext();
	const { setNft } = useNerdBackendContext();
	const { nftAddress } = useContext(NERDContext);
	const history = useHistory();

	const [imageId, setImageId] = useState<number>(0);
	const [isConfidential, setIsConfidential] = useState<boolean>(false);
	const [titleInput, setTitleInput] = useState<string>("");
	const [descriptionInput, setDescriptionInput] = useState<string>("");

	const handleMint = async () => {
		if (!session) return;
		const nftId = BigInt(
			Math.round(Math.random() * Number.MAX_SAFE_INTEGER),
		);

		const ptx = await session.mint(nftAddress, nftId);
		const minttx = (await ptx.receipt).tx as Mint;
		const nft = new PerunArtNFT(
			minttx.token,
			minttx.id,
			minttx.sender,
			new PerunArtMetadata(
				titleInput,
				descriptionInput,
				getPicsumUrl(imageId, 512, isConfidential),
				isConfidential,
				{ ts: new Date(), creator: session.address.toString() },
			),
		);

		await new Promise((resolve) => setTimeout(resolve, 500));

		const ok = await setNft(nft);
		if (!ok) {
			alert("failed to mint NFT");
			return;
		}

		history.push(`/nft/${minttx.token}/${minttx.id}`);
	};

	const mintReady =
		titleInput.length < TITLE_LIMIT &&
		descriptionInput.length < DESCRIPTION_LIMIT;

	return (
		<Container maxWidth="lg" sx={{ p: 2, flexGrow: 1 }}>
			<Grid container alignItems="stretch" spacing={1}>
				<Grid item xs={12} lg={6}>
					<MetadataForm
						imageId={imageId}
						setImageId={setImageId}
						mediaHeight={CARD_MEDIA_HEIGHT}
						isConfidential={isConfidential}
						setIsConfidential={setIsConfidential}
						titleInput={titleInput}
						setTitleInput={setTitleInput}
						descriptionInput={descriptionInput}
						setDescriptionInput={setDescriptionInput}
					/>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Card
						variant="outlined"
						sx={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<CardContent sx={{ flexGrow: 1 }}>
							<Typography variant="h4">Minting</Typography>
							<Typography gutterBottom>
								You are about to mint your very own NFT. This
								minting process happens entirely{" "}
								<b>off-chain</b>, secured by{" "}
								<a
									target="_blank"
									rel="noreferrer"
									href="https://erdstall.dev"
								>
									🍵 Erdstall
								</a>
								.
							</Typography>
							<Typography variant="h4">Process</Typography>
							<Typography gutterBottom>
								As soon as you <b>Mint</b>, a minting
								transaction is sent within the Layer-2 Erdstall
								platform. <b>This is completely free.</b> The
								Layer-1 costs for minting the NFT are only
								realised by the current owner at the time of
								withdrawing.
							</Typography>
							<Typography variant="h4">
								Confidential NFTs
							</Typography>
							<Typography gutterBottom>
								NERD introduces confidential NFTs, which allow
								only the owner to see the data attached to the
								NFT. You, the minter, can decide whether to make
								your NFT public or confidential. Confidential
								NFTs require you to be the current owner in
								order to be able to decrypt and view their data.
								<br />
								This is not possible on Layer-1, but Erdstall
								makes it possible in a secure, privacy
								preserving manner.
								<br />
								In the future, confidential NFTs can not only be
								used to grant access to single, fixed images,
								but also to encrypted content published for the
								NFT later.
							</Typography>
						</CardContent>
						<CardActions>
							<Grid
								container
								direction="column"
								spacing={1}
								justifyContent="flex-end"
							>
								<Grid item>
									<Button
										variant="contained"
										color="secondary"
										fullWidth
										onClick={handleMint}
										disabled={!mintReady}
										sx={{ mt: "auto" }}
									>
										mint
									</Button>
								</Grid>
							</Grid>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
}
