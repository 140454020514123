import { useEffect, useState } from "react";
import { Grid, Paper, Container, Typography } from "@mui/material";
import NFT from "@polycrypt/nerd-marketplace/src/server/nft";
import { NFTServerQueryFilter } from "@polycrypt/nerd-marketplace/src/server/nft_filter";
import NftCard from "./NftCard";

import useNerdBackendContext, {
	NerdAccount,
} from "../context/NerdBackendContext";
import useUserContext from "../context/UserContext";
import EditFilterDialog from "./EditFilterDialog";
import FilterDisplay from "./FilterDisplay";

interface NftGalleryProps {
	hardFilter: NFTServerQueryFilter;
}

export default function NftGallery(props: NftGalleryProps) {
	const { session } = useUserContext();
	const { getNfts, getAccounts } = useNerdBackendContext();

	const [nfts, setNfts] = useState<NFT[]>();
	const [showFilterDialog, setShowFilterDialog] = useState(false);
	const [filter, setFilter] = useState<NFTServerQueryFilter>(
		props.hardFilter,
	);

	const [knownUsers, setKnownUsers] = useState<NerdAccount[]>([]);
	useEffect(() => {
		getAccounts().then(setKnownUsers);
	}, [getAccounts, setKnownUsers]);

	const getMatchingNfts = () => {
		getNfts({
			...filter,
			...props.hardFilter,
		})
			.then((rnfts) => {
				setNfts(rnfts);
			})
			.catch((error) => {
				throw new Error(error);
			});
	};

	useEffect(getMatchingNfts, [filter, session, getNfts, props.hardFilter]);

	return (
		<Container
			maxWidth="xl"
			sx={{
				p: 2,
			}}
		>
			<EditFilterDialog
				open={showFilterDialog}
				closeDialog={() => setShowFilterDialog(false)}
				filter={filter}
				setFilter={(filter: NFTServerQueryFilter) =>
					setFilter({ ...filter, ...props.hardFilter })
				}
				disable={
					Object.keys(
						props.hardFilter,
					) as (keyof NFTServerQueryFilter)[]
				}
				knownUsers={knownUsers}
			/>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="stretch"
				spacing={4}
			>
				<Grid item xs={12}>
					<Paper>
						<FilterDisplay
							filter={filter}
							showFilterDialog={() => setShowFilterDialog(true)}
							knownUsers={knownUsers}
						/>
					</Paper>
				</Grid>
				{nfts && nfts.length > 0 ? (
					nfts.map((nft) => {
						return <NftGridItem key={nft.key} nft={nft} />;
					})
				) : (
					<NoNftMessage />
				)}
			</Grid>
		</Container>
	);
}

function NftGridItem(props: { nft: NFT }) {
	const { nft } = props;
	return (
		<Grid item xs={12} md={6} lg={4} xl={3}>
			<NftCard nft={nft} />
		</Grid>
	);
}

function NoNftMessage() {
	return (
		<Grid item xs={12}>
			<Paper
				sx={{
					minHeight: 350,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography
					variant="h3"
					textAlign="center"
					sx={{ verticalAlign: "middle" }}
				>
					No NFTs to display.
				</Typography>
			</Paper>
		</Grid>
	);
}
