import { Refresh } from "@mui/icons-material";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	Grid,
	FormControlLabel,
	Checkbox,
	DialogActions,
	Button,
	Typography,
	Card,
	CardActions,
	CardMedia,
	CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import useNerdBackendContext from "../context/NerdBackendContext";
import useUserContext from "../context/UserContext";
import { randomPicsumId, getPicsumUrl } from "../logic/utils";
import CopyButton from "./CopyButton";

interface AccountDialogProps {
	open: boolean;
	handleClose: () => void;
}

export default function AccountDialog({
	open,
	handleClose,
}: AccountDialogProps) {
	const { session, initializeUserData } = useUserContext();
	const { getAccount, configureAccount } = useNerdBackendContext();

	const [userName, setUserName] = useState<string>("");
	const [profilePictureId, setProfilePictureId] = useState(0);
	const [premium, setPremium] = useState<boolean>(false);
	const [loadingImage, setLoadingImage] = useState<boolean>(false);

	useEffect(() => {
		const fetchUserData = async () => {
			if (!session) return;
			try {
				const account = await getAccount(session.address);
				if (account) {
					const userData = {
						name: account.name,
						pictureId: account.profileImageId,
						premium: account.premium,
					};
					initializeUserData(userData);
					setUserName(account.name);
					setProfilePictureId(account.profileImageId);
					setPremium(account.premium);
				} else {
					randomPicsumId().then(setProfilePictureId);
				}
			} catch (e) {
				alert("Unable to fetch account");
				return;
			}
		};
		fetchUserData();
	}, [session, getAccount, initializeUserData]);

	if (!session) return <></>;

	const handleSubmit = () => {
		const userData = {
			name: userName.trim(),
			pictureId: profilePictureId,
			premium: premium,
		};
		initializeUserData(userData);
		configureAccount({
			name: userName.trim(),
			premium: premium,
			profileImageId: profilePictureId,
			address: session.address,
		})
			.then((ok) => {
				if (!ok) throw new Error("Error in request");
				handleClose();
			})
			.catch(() => alert("Unable to update account"));
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
			<DialogTitle>Your Account</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item md={3}>
						<Card sx={{ border: "none", boxShadow: "none" }}>
							<CardMedia
								component="img"
								src={getPicsumUrl(profilePictureId, 256, false)}
								height="256"
							/>
							<CardActions>
								<Button
									startIcon={<Refresh />}
									endIcon={
										loadingImage && (
											<CircularProgress size={20} />
										)
									}
									variant="contained"
									color="secondary"
									fullWidth
									onClick={() => {
										setLoadingImage(true);
										randomPicsumId()
											.then(setProfilePictureId)
											.then(() => setLoadingImage(false));
									}}
									sx={{ mt: 2 }}
								>
									{" "}
									new image{" "}
								</Button>
							</CardActions>
						</Card>
					</Grid>
					<Grid item md={9}>
						<Typography
							variant="h6"
							sx={{ overflowWrap: "anywhere" }}
						>
							<b>Address:</b> {session.address.toString()}
							<CopyButton text={session.address.toString()} />
						</Typography>
						<TextField
							margin="dense"
							id="name"
							label="Name"
							fullWidth
							variant="filled"
							color="secondary"
							onChange={(event) => {
								setUserName(event.target.value);
							}}
							value={userName}
						/>
						<FormControlLabel
							label="Premium"
							id="name"
							control={
								<Checkbox
									checked={premium}
									onChange={() =>
										setPremium((previous) => !previous)
									}
								/>
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="secondary"
					onClick={handleSubmit}
				>
					save changes
				</Button>
				<Button onClick={handleClose}>cancel</Button>
			</DialogActions>
		</Dialog>
	);
}
