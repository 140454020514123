// SPDX-License-Identifier: Apache-2.0

import { Route, Redirect, Switch } from "react-router-dom";
import Home from "./pages/Home";
import MyNfts from "./pages/MyNfts";
import NftDetails from "./pages/NftDetails";
import MintNft from "./pages/MintNft";
import Onboarding from "./pages/Onboarding";
import useUserContext from "./context/UserContext";

export default function AppRoutes() {
	const { session } = useUserContext();
	return (
		<Switch>
			<Route exact path="/">
				{session ? <Redirect to="/home" /> : <Onboarding />}
			</Route>
			<Route exact path="/home">
				<Home />
			</Route>
			<Route exact path="/me/nfts">
				{session ? <MyNfts /> : <Redirect to="/" />}
			</Route>
			<Route exact path="/me/mint">
				{session ? <MintNft /> : <Redirect to="/" />}
			</Route>
			<Route path="/nft/:token/:id">
				<NftDetails />
			</Route>
			<Route>
				{session ? <Redirect to="/home" /> : <Redirect to="/" />}
			</Route>
		</Switch>
	);
}
