import { ContentCopy } from "@mui/icons-material";
import { IconButton } from "@mui/material";

interface CopyButtonProps {
	text: string;
}

export default function CopyButton({ text }: CopyButtonProps) {
	return (
		<IconButton onClick={() => navigator.clipboard.writeText(text)}>
			<ContentCopy />
		</IconButton>
	);
}
