import { Avatar } from "@mui/material";

import Picsum from "./Picsum";

interface props {
	accountPictureId?: number;
}

export default function PicsumAvatar(props: props) {
	return (
		<Avatar>
			<Picsum
				id={props.accountPictureId}
				size={100}
				style={{
					width: 100,
					height: 100,
				}}
			/>
		</Avatar>
	);
}
