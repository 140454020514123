import { ReactElement, useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
	List,
	ListItem,
	ListItemButton,
	Button,
} from "@mui/material";
import { CheckCircle, LocalOffer, Shield } from "@mui/icons-material";

import { utils } from "ethers";

import NFT from "@polycrypt/nerd-marketplace/src/server/nft";
import { PerunArtMetadata } from "@polycrypt/nerd-marketplace/src/nft";

import useUserContext from "../context/UserContext";
import useNerdBackendContext, {
	NerdAccount,
} from "../context/NerdBackendContext";
import { Address } from "@polycrypt/erdstall/ledger";
import PicsumAvatar from "./PicsumAvatar";

export const CARD_MEDIA_HEIGHT = 400;

interface NftCardProps {
	nft: NFT;
}

export default function NftCard(props: NftCardProps) {
	const { nft } = props;
	let metadata: PerunArtMetadata | undefined = undefined;
	if (nft.metadata) {
		metadata = PerunArtMetadata.from(nft.metadata!);
	}

	const { session } = useUserContext();
	const { getAccount } = useNerdBackendContext();
	const history = useHistory();

	const displayAsConfidential =
		metadata &&
		metadata.confidential &&
		(!session || !session.address.equals(nft.owner));

	const [premiumCreator, setPremiumCreator] = useState<NerdAccount>();

	// https://github.com/facebook/react/issues/14920
	// This is still not the best solution, because it does nothing to fix the
	// problem where this component gets rerendered because the surrounding
	// context changes.
	const fetchPremiumCreator = useCallback(() => {
		if (!metadata) return;
		if (metadata!.history.length === 0) return;
		if (metadata!.history[0].owner === undefined) return;

		getAccount(Address.fromString(metadata!.history[0].owner)).then(
			(account) => {
				if (account && account.premium) {
					if (!premiumCreator) setPremiumCreator(account);
				}
			},
		);
		// Alternatively the below, but this calls the hook four times in total...
		// }, [premiumCreator, getAccount, metadata]);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchPremiumCreator();
	}, [fetchPremiumCreator]);

	const [showDetailsList, setShowDetailsList] = useState(false);
	return (
		<Card
			onClick={() => history.push(`/nft/${nft.token}/${nft.id}`)}
			sx={{
				position: "relative",
				height: "100%",
				"&:hover": {
					boxShadow: (theme) =>
						`0 5px 15px ${theme.palette.secondary.dark}`,
				},
			}}
		>
			<List
				onMouseOver={() => setShowDetailsList(true)}
				onMouseOut={() => setShowDetailsList(false)}
				sx={{
					position: "absolute",
					zIndex: (theme) => theme.zIndex.mobileStepper,
				}}
			>
				{premiumCreator && (
					<DetailBadge
						color="primary.main"
						icon={
							<PicsumAvatar
								accountPictureId={premiumCreator.profileImageId}
							/>
						}
						showDetails={showDetailsList}
						details={premiumCreator.name}
					/>
				)}
				{session && session.address.equals(nft.owner) && (
					<DetailBadge
						color="success.light"
						icon={<CheckCircle />}
						showDetails={showDetailsList}
						details="owned"
					/>
				)}
				{metadata && metadata.confidential && (
					<DetailBadge
						color="primary.dark"
						icon={<Shield />}
						showDetails={showDetailsList}
						details="confidential"
					/>
				)}
				{nft.offer && (
					<DetailBadge
						color="secondary.dark"
						icon={<LocalOffer />}
						showDetails={showDetailsList}
						details={`${utils.formatEther(nft.offer.amount)} PRN`}
					/>
				)}
			</List>
			<CardActionArea>
				<CardMedia
					component="img"
					src={
						metadata &&
						(displayAsConfidential
							? metadata.image
							: metadata.clearImage())
					}
					height={CARD_MEDIA_HEIGHT}
					alt={`Missing Image for ${nft.id}`}
					sx={{
						fontSize: 24,
						textAlign: "center",
					}}
				/>
				<CardContent>
					<Typography gutterBottom variant="h5">
						{nft.name}
					</Typography>
					<Typography variant="body1" color="textSecondary">
						{nft.description}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

interface DetailBadgeProps {
	color: string;
	icon: ReactElement;
	showDetails?: boolean;
	details: string;
}

function DetailBadge(props: DetailBadgeProps) {
	return (
		<ListItem sx={{ p: 0 }}>
			<ListItemButton>
				<Button
					variant="contained"
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: props.color,
						px: 1,
						minWidth: 0,
						"&:hover": { backgroundColor: props.color },
					}}
				>
					<Typography sx={props.showDetails ? { pr: 1 } : {}}>
						{props.showDetails && props.details}
					</Typography>
					{props.icon}
				</Button>
			</ListItemButton>
		</ListItem>
	);
}
