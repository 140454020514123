// SPDX-License-Identifier: Apache-2.0

import { createContext, useContext, FC } from "react";

import NFT, {
	PerunArtNFT,
	key,
} from "@polycrypt/nerd-marketplace/src/server/nft";
import Account from "@polycrypt/nerd-marketplace/src/account";
import {
	NFTServerQueryFilter,
	NFTServerQueryFilterToQueryString,
} from "@polycrypt/nerd-marketplace/src/server/nft_filter";
import { Address } from "@polycrypt/erdstall/ledger";
import { TradeOffer } from "@polycrypt/erdstall/api/transactions";
import { TypedJSON } from "@polycrypt/erdstall/export/typedjson";

interface NerdBackendFunctions {
	getNft: (token: Address, tokenId: bigint) => Promise<NFT | undefined>;
	getNfts: (filter: NFTServerQueryFilter) => Promise<NFT[]>;
	setNft: (nft: PerunArtNFT) => Promise<Boolean>;
	getTrade: (id: string) => Promise<TradeOffer | undefined>;
	getTrades: () => Promise<TradeOffer[]>;
	postTrade: (offer: TradeOffer) => Promise<Boolean>;
	deleteTrade: (token: Address, tokenId: bigint) => Promise<Boolean>;
	getAccount: (address: Address) => Promise<NerdAccount | undefined>;
	getAccounts: () => Promise<NerdAccount[]>;
	configureAccount: (account: Account) => Promise<Boolean>;
}

const NerdBackendContext = createContext<NerdBackendFunctions>(
	{} as NerdBackendFunctions,
);

export interface NerdAccount {
	address: Address;
	name: string;
	premium: boolean;
	profileImageId: number;
}

function getNft(
	nerdUrl: URL,
	perunArtAddress: Address,
): (token: Address, tokenId: bigint) => Promise<NFT | undefined> {
	return async (token, tokenId) => {
		const url = new URL(`nft/${token}/${tokenId}`, nerdUrl);
		const response = await fetch(url.toString(), {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
			},
		});
		if (response.ok) {
			return TypedJSON.parse(await response.text(), NFT);
		}
		if (response.status === 404) {
			return undefined;
		}
		throw new Error(await response.text());
	};
}

function getNfts(
	nerdUrl: URL,
	perunArtAddress: Address,
): (filter: NFTServerQueryFilter) => Promise<NFT[]> {
	return async (filter) => {
		const url = new URL(
			`nfts?${NFTServerQueryFilterToQueryString(filter)}`,
			nerdUrl,
		);
		const response = await fetch(url.toString(), {
			method: "GET",
			headers: { "Content-Type": "application/json; charset=UTF-8" },
		});
		const text = await response.text();
		if (response.ok) {
			return TypedJSON.parseAsArray(text, NFT);
		}
		throw Error(text);
	};
}

function setNft(nerdUrl: URL): (pnft: PerunArtNFT) => Promise<Boolean> {
	return async (nft) => {
		const metadata = JSON.stringify(nft.pamd.toMetadata());
		const url = new URL(`metadata/${nft.token}/${nft.id}`, nerdUrl);
		const response = await fetch(url.toString(), {
			method: "PUT",
			body: metadata,
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
			},
		}).catch((error) => {
			console.error(error);
			return { ok: false };
		});
		return response.ok;
	};
}

function getTrade(
	nerdUrl: URL,
): (id: string) => Promise<TradeOffer | undefined> {
	return async (id) => {
		const url = new URL(`trade/${id}`, nerdUrl);
		const response = await fetch(url.toString(), {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
			},
		});

		if (response.ok) {
			return TypedJSON.parse(await response.text(), TradeOffer);
		}
		if (response.status === 404) {
			return undefined;
		}
		throw new Error(await response.text());
	};
}

function getTrades(nerdUrl: URL): () => Promise<TradeOffer[]> {
	return async () => {
		const url = new URL("trades", nerdUrl);
		const response = await fetch(url.toString(), {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
			},
		});

		if (response.ok) {
			return TypedJSON.parseAsArray(await response.text(), TradeOffer);
		}
		throw new Error(await response.text());
	};
}

function postTrade(nerdUrl: URL): (offer: TradeOffer) => Promise<Boolean> {
	return async (offer) => {
		const url = new URL("trades", nerdUrl);
		const response = await fetch(url.toString(), {
			method: "POST",
			headers: { "Content-Type": "application/json; charset=UTF-8" },
			body: TypedJSON.stringify(offer, TradeOffer),
		});
		return response.ok;
	};
}

function deleteTrade(
	nerdUrl: URL,
): (token: Address, tokenId: bigint) => Promise<Boolean> {
	return async (token, tokenId) => {
		const url = new URL(`trade/${key(token, tokenId)}`, nerdUrl);
		const response = await fetch(url.toString(), {
			method: "DELETE",
			headers: { "Content-Type": "application/json; charset=UTF-8" },
		});
		return response.ok;
	};
}

function getAccount(
	nerdUrl: URL,
): (address: Address) => Promise<NerdAccount | undefined> {
	return async (address) => {
		const url = new URL(`account/${address}`, nerdUrl);
		const response = await fetch(url.toString(), {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
			},
		});
		if (response.ok) {
			const account = JSON.parse(await response.text());
			account.address = Address.fromString(account.address);
			return account as NerdAccount;
		}
		if (response.status === 404) {
			return undefined;
		}
		throw new Error(await response.text());
	};
}

function getAccounts(nerdUrl: URL): () => Promise<NerdAccount[]> {
	return async () => {
		const url = new URL("accounts", nerdUrl);
		const response = await fetch(url.toString(), {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
			},
		});

		if (response.ok) {
			const accounts = JSON.parse(await response.text());
			for (let account of accounts) {
				account.address = Address.fromString(account.address);
			}
			return accounts as NerdAccount[];
		}
		throw new Error(await response.text());
	};
}

function configureAccount(
	nerdUrl: URL,
): (account: Account) => Promise<Boolean> {
	return async (account) => {
		const url = new URL(`account/${account.address}`, nerdUrl);
		const response = await fetch(url.toString(), {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
			},
			body: JSON.stringify({
				name: account.name,
				premium: account.premium,
				profileImageId: account.profileImageId,
			}),
		});
		return response.ok;
	};
}

const makeApiMethods = (nerdUrl: URL, perunArtAddress: Address) => ({
	getNft: getNft(nerdUrl, perunArtAddress),
	getNfts: getNfts(nerdUrl, perunArtAddress),
	setNft: setNft(nerdUrl),
	getTrade: getTrade(nerdUrl),
	getTrades: getTrades(nerdUrl),
	postTrade: postTrade(nerdUrl),
	deleteTrade: deleteTrade(nerdUrl),
	getAccount: getAccount(nerdUrl),
	getAccounts: getAccounts(nerdUrl),
	configureAccount: configureAccount(nerdUrl),
});

const NerdBackendProvider: FC<{ nerdUrl: URL; perunArtAddress: Address }> = (
	props,
) => {
	return (
		<NerdBackendContext.Provider
			value={makeApiMethods(props.nerdUrl, props.perunArtAddress)}
			{...props}
		/>
	);
};

const useNerdBackendContext = () => useContext(NerdBackendContext);

export default useNerdBackendContext;
export { NerdBackendProvider, useNerdBackendContext, makeApiMethods };
