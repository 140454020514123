import { useContext, useState } from "react";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
	TextField,
	Button,
} from "@mui/material";

import { utils } from "ethers";

import NFT from "@polycrypt/nerd-marketplace/src/server/nft";
import { Amount, Assets, Tokens } from "@polycrypt/erdstall/ledger/assets";
import { NERDContext } from "../AppContext";
import { TradeOffer } from "@polycrypt/erdstall/api/transactions";
import useUserContext from "../context/UserContext";
import useNerdBackendContext from "../context/NerdBackendContext";

interface SellModalProps {
	nft: NFT;
	open: boolean;
	handleClose: () => void;
}

export default function SellModal({ nft, open, handleClose }: SellModalProps) {
	const { token } = useContext(NERDContext);
	const { session } = useUserContext();
	const { postTrade } = useNerdBackendContext();

	const [offerInput, setOfferInput] = useState<string>("");
	const [offerPrice, setOfferPrice] = useState<bigint>();
	const [validOffer, setValidOffer] = useState<boolean>(true);

	const parseAmountInput = (val: string): bigint | undefined => {
		try {
			const res = utils.parseEther(val);
			return res.toBigInt();
		} catch (reason) {
			return;
		}
	};

	const handlePostOffer = async () => {
		if (!offerPrice) return;
		const offer = new Assets({
			token: nft.token,
			asset: new Tokens([nft.id]),
		});
		const expecting = new Assets({
			token,
			asset: new Amount(offerPrice),
		});
		let to: TradeOffer;
		try {
			to = await session!.createOffer(offer, expecting);
		} catch {
			alert("failed to sell NFT");
			return;
		}
		await postTrade(to);
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Offer {nft.name} for sale</DialogTitle>
			<DialogContent>
				<List>
					<ListItem>
						<ListItemText>
							<b>Description:</b> {nft.description}
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText>
							<b>Address:</b> {nft.token.toString()}
						</ListItemText>
					</ListItem>
					<ListItem>
						<TextField
							margin="dense"
							id="price"
							label="Price"
							fullWidth
							variant="standard"
							color="secondary"
							onBlur={() => {
								setValidOffer(!!offerPrice);
							}}
							onChange={(event) => {
								setOfferInput(event.target.value);
								setOfferPrice(
									parseAmountInput(event.target.value),
								);
							}}
							error={!validOffer}
							value={offerInput}
						/>
					</ListItem>
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handlePostOffer}>post offer</Button>
				<Button onClick={handleClose}>cancel</Button>
			</DialogActions>
		</Dialog>
	);
}
