// SPDX-License-Identifier: Apache-2.0

import { Attribute, NFTMetadata } from "@polycrypt/erdstall/ledger/backend";

export const OWNERSHIP_HISTORY = "Ownership History";
export const CONFIDENTIAL = "confidential";

export interface OwnershipEntry {
	ts: Date;
	owner: string;
}

export class PerunArtMetadata {
	name?: string;
	description?: string;
	image?: string;
	confidential: boolean;
	history: OwnershipEntry[] = [];

	constructor(
		name?: string,
		description?: string,
		image?: string,
		confidential: boolean = false,
		creator?: { ts?: Date; creator: string },
	) {
		this.name = name;
		this.description = description;
		this.image = image;
		this.confidential = confidential;
		if (creator)
			this.pushOwner(
				creator.ts ? creator.ts : new Date(),
				creator.creator,
			);
	}

	static from(md: NFTMetadata): PerunArtMetadata {
		const confidential = md.attributes
			? hasAttributeProperty(md.attributes, CONFIDENTIAL)
			: false;

		const pamd = new PerunArtMetadata(
			md.name,
			md.description,
			md.image,
			confidential,
		);

		const hist = md.attributes
			? findAttributeValue(md.attributes, OWNERSHIP_HISTORY)
			: "";
		const history =
			typeof hist === "string" ? parseOwnershipHistory(hist) : [];

		for (const entry of history) {
			pamd.pushOwner(entry.ts, entry.owner);
		}

		return pamd;
	}

	toMetadata(): NFTMetadata {
		const attributes: Attribute[] = [];
		if (this.confidential) attributes.push({ value: CONFIDENTIAL });
		if (this.history.length > 0)
			attributes.push(this.ownershipHistoryAttribute());

		return {
			name: this.name,
			description: this.description,
			image: this.image,
			attributes: attributes.length > 0 ? attributes : undefined,
		};
	}

	clearImage(): string | undefined {
		return this.image?.split("?")[0];
	}

	lastOwner(): string | undefined {
		if (this.history.length === 0) return;
		return this.history[this.history.length - 1].owner;
	}

	ownershipHistoryAttribute(): Attribute {
		return {
			trait_type: OWNERSHIP_HISTORY,
			value: formatOwnershipHistory(this.history),
		};
	}

	update(md: NFTMetadata): void {
		md.name = this.name;
		md.description = this.description;
		md.image = this.image;

		this.updateAttributes(md);
	}

	updateAttributes(md: NFTMetadata): void {
		if (md.attributes) {
			upsertAttributeByTrait(
				md.attributes,
				this.ownershipHistoryAttribute(),
			);
			if (
				this.confidential &&
				!hasAttributeProperty(md.attributes, CONFIDENTIAL)
			) {
				md.attributes.push({ value: CONFIDENTIAL });
			}
		} else {
			md.attributes = this.toMetadata().attributes;
		}
	}

	pushOwner(ts: Date, owner: string): void {
		this.history.push({ ts: ts, owner: owner });
	}
}

function hasAttributeProperty(attrs: Attribute[], value: string): boolean {
	for (const attr of attrs) {
		if (!attr.trait_type && attr.value === value) return true;
	}
	return false;
}

function findAttributeValue(
	attrs: Attribute[],
	trait: string,
): string | number | undefined {
	for (const attr of attrs) {
		if (attr.trait_type == trait) return attr.value;
	}
}

function upsertAttributeByTrait(attrs: Attribute[], tattr: Attribute) {
	if (tattr.trait_type === undefined)
		throw new Error("Target Attribute must have trait_type");
	for (const [i, attr] of attrs.entries()) {
		if (attr.trait_type === tattr.trait_type) {
			attrs[i] = tattr;
			return;
		}
	}
	attrs.push(tattr);
}

function parseOwnershipHistory(hist: string): OwnershipEntry[] {
	if (hist.length === 0) return [];
	return hist.split(";").map((entry) => {
		const [ts, owner] = entry.split(",");
		return { ts: new Date(ts), owner };
	});
}

export function formatOwnershipHistory(hist: OwnershipEntry[]): string {
	return hist.map((entry) => `${entry.ts.toJSON()},${entry.owner}`).join(";");
}
