import { AppBar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import ErdstallLogo from "../images/erdstall-logo.png";
import VersionInfo from "./VersionInfo";

export default function FooterBar() {
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<AppBar
			position="relative"
			color="inherit"
			component="footer"
			sx={{ zIndex: 0, textAlign: "right" }}
		>
			<Box
				sx={{
					flexGrow: 1,
					display: "flex",
				}}
				textAlign="center"
			>
				<Box
					flexGrow={1}
					justifyContent="center"
					textAlign="center"
					alignItems="center"
					display="flex"
				>
					{!isSmall && (
						<Typography variant="h5" sx={{ opacity: 0.5 }}>
							Powered by
						</Typography>
					)}
					<Link
						to={{ pathname: "https://erdstall.dev/" }}
						target="_blank"
					>
						<img
							src={ErdstallLogo}
							style={{
								maxHeight: "100%",
								maxWidth: "100%",
								height: "auto",
								width: "auto",
							}}
							alt="ERDSTALL"
						/>
					</Link>
				</Box>
				<Box maxWidth="250px">{!isSmall && <VersionInfo />}</Box>
			</Box>
		</AppBar>
	);
}
