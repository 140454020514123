import { Box } from "@mui/system";
import NftGallery from "../components/NftGallery";

export default function Home() {
	return (
		<Box flexGrow={1}>
			<NftGallery hardFilter={{}} />
		</Box>
	);
}
