// SPDX-License-Identifier: Apache-2.0

import { ParsedQs } from "qs";

import { NFTQueryFilter, TokenQuery } from "#nerd/nft";
import { Address } from "@polycrypt/erdstall/ledger";

export interface NFTServerQueryFilter extends NFTQueryFilter {
	owner?: Address;
	forSale?: boolean;
}

export function NFTServerQueryFilterToQueryString(
	filter: NFTServerQueryFilter,
): string {
	const queryString = [];
	if (filter.forSale !== undefined) {
		queryString.push(`forSale=${filter.forSale}`);
	}

	if (filter.confidential !== undefined) {
		queryString.push(`confidential=${filter.confidential}`);
	}

	if (filter.collections) {
		queryString.push(
			filter.collections.map((addr) => `collections[]=${addr}`).join("&"),
		);
	}

	if (filter.owner) {
		queryString.push(`owner=${filter.owner}`);
	}

	if (filter.tokens) {
		queryString.push(
			filter.tokens
				.map(
					(tq, i) =>
						`tokens[${i}][address]=${tq.token}&tokens[${i}][id]=${tq.id}`,
				)
				.join("&"),
		);
	}

	return queryString.join("&");
}

export function NFTServerQueryFilterParse(obj: ParsedQs): NFTServerQueryFilter {
	return {
		forSale:
			typeof obj.forSale === "string"
				? JSON.parse(obj.forSale)
				: undefined,
		confidential:
			typeof obj.confidential === "string"
				? JSON.parse(obj.confidential)
				: undefined,
		owner:
			typeof obj.owner === "string"
				? Address.fromString(obj.owner)
				: undefined,
		token:
			typeof obj.token === "object"
				? parseToken(obj.token as ParsedQs)
				: undefined,
		tokens: Array.isArray(obj.tokens)
			? (obj.tokens as ParsedQs[]).map(parseToken)
			: undefined,
		collections: Array.isArray(obj.collections)
			? (obj.collections as string[]).map(Address.fromString)
			: undefined,
	};
}

function parseToken(json: { address?: string; id?: string }): TokenQuery {
	if (!json.address || !json.id) {
		throw new Error("invalid token encoding");
	}

	return {
		token: Address.fromString(json.address),
		id: BigInt(json.id),
	};
}
