import { createContext } from "react";
import { ClientConfig } from "@polycrypt/erdstall/api/responses";
import { Address } from "@polycrypt/erdstall/ledger";

import { nerdURL, operatorURL, token, nft } from "./Constants";

export interface NERDState {
	config?: ClientConfig;
	operatorURL: URL;
	nerdURL: URL;
	token: Address;
	nftAddress: Address;
}

// https://reactjs.org/docs/context.html#reactcreatecontext
// TODO: Put sane defaults here, which would ease testability of components
// like described in the aforementioned link.
export const NERDContext = createContext<NERDState>({
	operatorURL: operatorURL,
	nerdURL: nerdURL,
	token: token,
	nftAddress: nft,
});
