import background from "../images/erdstallbackground.png";
import { Box } from "@mui/system";

export default function Onboarding() {
	return (
		<Box
			flexGrow={1}
			sx={{
				overflow: "hidden",
				position: "relative",
			}}
		>
			<img
				src={background}
				style={{
					minWidth: "100%",
					minHeight: "100%",
					height: "auto",
					width: "auto",
					position: "absolute",
					bottom: 0,
					right: 0,
					zIndex: -1,
				}}
				alt=""
			/>
		</Box>
	);
}
