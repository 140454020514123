import { Address } from "@polycrypt/erdstall/ledger";

const config = require("./config.json");

export const operatorURL = new URL(
	`${config.OperatorProtocol}://${config.OperatorAddr}:${config.OperatorPort}/${config.OperatorEndpoint}`,
);

export const nerdURL = new URL(
	`${config.NERDProtocol}://${config.NERDAddr}:${config.NERDPort}`,
);

export const token = Address.fromString(config.TokenAddress);
export const nft = Address.fromString(config.PerunArtAddress);
