import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from "@mui/material";
import { Assets, Tokens } from "@polycrypt/erdstall/ledger/assets";
import NFT from "@polycrypt/nerd-marketplace/src/server/nft";
import { useHistory } from "react-router";
import useUserContext from "../context/UserContext";

interface BurnModalProps {
	nft: NFT;
	open: boolean;
	handleClose: () => void;
}

export default function BurnModal({ nft, open, handleClose }: BurnModalProps) {
	const { session } = useUserContext();
	const history = useHistory();

	const burnNft = async () => {
		try {
			await session!.burn(
				new Assets({
					token: nft.token,
					asset: new Tokens([nft.id]),
				}),
			);
		} catch {
			alert("failed to burn NFT");
			return;
		}
		history.push("/me/nfts");
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Permanently Burn NFT?</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to permanently burn this token? This
					can not be undone!
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={burnNft}>confirm burn</Button>
				<Button onClick={handleClose}>cancel</Button>
			</DialogActions>
		</Dialog>
	);
}
