import {
	FilterAlt,
	LibraryBooks,
	LocalOffer,
	Person,
	Shield,
} from "@mui/icons-material";
import {
	List,
	ListItem,
	Button,
	ListItemIcon,
	ListItemText,
	useTheme,
	useMediaQuery,
	ListItemButton,
} from "@mui/material";
import { Address } from "@polycrypt/erdstall/ledger";
import { NFTServerQueryFilter } from "@polycrypt/nerd-marketplace/src/server/nft_filter";
import { NerdAccount } from "../context/NerdBackendContext";
import useUserContext from "../context/UserContext";

interface FilterDisplayProps {
	filter: NFTServerQueryFilter;
	showFilterDialog: () => void;
	knownUsers: NerdAccount[];
}

export default function FilterDisplay({
	filter,
	showFilterDialog,
	knownUsers,
}: FilterDisplayProps) {
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down("md"));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { session } = useUserContext();

	const getDisplay = (x?: boolean) => {
		switch (x) {
			case true:
				return "Only";
			case false:
				return "None";
			default:
				return "All";
		}
	};
	const displayConfidential = getDisplay(filter.confidential);
	const displayForSale = getDisplay(filter.forSale);

	const findKnownUserByAddress = (address: Address): string => {
		const matching = knownUsers.filter((x) => x.address.equals(address))[0];
		if (matching) {
			return matching.name;
		}
		return address.toString();
	};

	let displayOwner;
	if (session && filter.owner && session.address.equals(filter.owner)) {
		displayOwner = "Me";
	} else if (filter.owner) {
		displayOwner = findKnownUserByAddress(filter.owner);
	} else {
		displayOwner = "---";
	}
	let displayCollections = filter.collections
		? filter.collections.toString()
		: "---";
	return (
		<List
			sx={{
				display: "flex",
				flexDirection: "row",
				p: 0,
			}}
		>
			{isSmall ? (
				<ListItem>
					<ListItemButton onClick={showFilterDialog}>
						<FilterAlt fontSize="large" />
					</ListItemButton>
				</ListItem>
			) : (
				<ListItem>
					<ListItemIcon>
						<FilterAlt fontSize="large" />
					</ListItemIcon>
					<Button
						variant="contained"
						color="secondary"
						onClick={showFilterDialog}
					>
						Edit Filter
					</Button>
				</ListItem>
			)}
			{!isMobile && (
				<ListItem sx={{ overflow: "auto" }}>
					<ListItemIcon>
						<Shield />
					</ListItemIcon>
					<ListItemText>
						{!isSmall && <b>Confidential:</b>} {displayConfidential}
					</ListItemText>
				</ListItem>
			)}
			{!isMobile && (
				<ListItem sx={{ overflow: "auto" }}>
					<ListItemIcon>
						<LocalOffer />
					</ListItemIcon>
					<ListItemText>
						{!isSmall && <b>For Sale:</b>} {displayForSale}
					</ListItemText>
				</ListItem>
			)}
			{!isMobile && (
				<ListItem sx={{ overflow: "auto" }}>
					<ListItemIcon>
						<Person />
					</ListItemIcon>
					<ListItemText>
						{!isSmall && <b>Owner:</b>} {displayOwner}
					</ListItemText>
				</ListItem>
			)}
			{!isMobile && (
				<ListItem sx={{ overflow: "auto" }}>
					<ListItemIcon>
						<LibraryBooks />
					</ListItemIcon>
					<ListItemText>
						{!isSmall && <b>Collection:</b>} {displayCollections}
					</ListItemText>
				</ListItem>
			)}
		</List>
	);
}
