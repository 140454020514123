// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { cyan, blueGrey } from "@mui/material/colors";

import HeaderBar from "./components/HeaderBar";

import { EnclaveWSProvider, Enclave } from "@polycrypt/erdstall/enclave";
import { ClientConfig } from "@polycrypt/erdstall/api/responses";

import { NERDContext, NERDState } from "./AppContext";
import { nerdURL, operatorURL, token, nft as nftAddress } from "./Constants";

import { NerdBackendProvider } from "./context/NerdBackendContext";
import { UserProvider } from "./context/UserContext";
import FooterBar from "./components/FooterBar";
import AppRoutes from "./AppRoutes";
import { Box } from "@mui/system";

const appTheme = createTheme({
	palette: {
		primary: {
			main: blueGrey[900],
		},
		secondary: {
			main: cyan[300],
		},
		background: {
			default: "#eeeeee",
		},
	},
});

function App() {
	const [state, setState] = React.useState<NERDState>({
		operatorURL: operatorURL,
		nerdURL: nerdURL,
		token: token,
		nftAddress: nftAddress,
	});

	React.useEffect(() => {
		const enc = new Enclave(new EnclaveWSProvider(state.operatorURL));
		enc.on("config", (config: ClientConfig) => {
			setState((s) => {
				return { ...s, config: config };
			});
			enc.disconnect();
		});
		enc.connect();
	}, [state.operatorURL]);

	return (
		<ThemeProvider theme={appTheme}>
			<NERDContext.Provider value={state}>
				<CssBaseline>
					<NerdBackendProvider
						nerdUrl={nerdURL}
						perunArtAddress={nftAddress}
					>
						<UserProvider>
							<Box
								display="flex"
								flexDirection="column"
								sx={{ minHeight: "100vh" }}
							>
								<HeaderBar />
								<AppRoutes />
								<FooterBar />
							</Box>
						</UserProvider>
					</NerdBackendProvider>
				</CssBaseline>
			</NERDContext.Provider>
		</ThemeProvider>
	);
}

export default App;
