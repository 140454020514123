// SPDX-License-Identifier: Apache-2.0
import { Typography } from "@mui/material";

declare var REACT_APP_REPO_URL: string;
declare var REACT_APP_BRANCH: string;
declare var REACT_APP_COMMIT_HASH: string;
declare var REACT_APP_DEPLOY_DATE: string;

function VersionInfo() {
	const repoURL = REACT_APP_REPO_URL;
	const branch = REACT_APP_BRANCH;
	const commitHash = REACT_APP_COMMIT_HASH;
	const buildTime = REACT_APP_DEPLOY_DATE;

	if (!repoURL || !commitHash || !buildTime || !branch) {
		return <></>;
	}

	const commitURL = `${repoURL}/tree/${commitHash}`;

	return (
		<Typography variant="subtitle2" sx={{ wordBreak: "break-all" }}>
			<a
				href={commitURL}
				target="_blank"
				rel="noreferrer"
			>{`${branch}@${commitHash}`}</a>
			<br />
			at {buildTime}
		</Typography>
	);
}

export default VersionInfo;
