import { getPicsumUrl } from "../logic/utils";

interface props {
	id?: number;
	size?: number;
	confidential?: boolean;
	style: {};
}

export default function Picsum(props: props) {
	const defaultSize = 200;
	const size = props.size || defaultSize;

	if (!props.id)
		return (
			<img
				src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
				width="0"
				height="0"
				alt=""
			/>
		);

	return (
		<img
			src={getPicsumUrl(props.id, size, props.confidential)}
			style={props.style}
			alt={`PICSUM ${props.id}`}
		/>
	);
}
