import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormLabel,
	FormControlLabel,
	DialogActions,
	Button,
	Radio,
	RadioGroup,
	Autocomplete,
} from "@mui/material";
import { NFTServerQueryFilter } from "@polycrypt/nerd-marketplace/src/server/nft_filter";
import { Address } from "@polycrypt/erdstall/ledger";

import { utils } from "ethers";
import { NerdAccount } from "../context/NerdBackendContext";

interface EditFilterDialogProps {
	open: boolean;
	filter: NFTServerQueryFilter;
	setFilter: (filter: NFTServerQueryFilter) => void;
	closeDialog: () => void;
	disable: (keyof NFTServerQueryFilter)[];
	knownUsers: NerdAccount[];
}

export default function EditFilterDialog({
	open,
	filter,
	setFilter,
	closeDialog,
	disable,
	knownUsers,
}: EditFilterDialogProps) {
	const findKnownUserByAddress = (address: Address): string => {
		const matching = knownUsers.filter((x) => x.address.equals(address))[0];
		if (matching) {
			return matching.name;
		}
		return address.toString();
	};

	const [confidential, setConfidential] = useState<boolean>();
	const [forSale, setForSale] = useState<boolean>();

	const [ownerInput, setOwnerInput] = useState(
		filter.owner ? findKnownUserByAddress(filter.owner) : "",
	);
	const [owner, setOwner] = useState<Address>();
	const [validOwnerInput, setValidOwnerInput] = useState<boolean>();

	useEffect(() => {
		const findAddressForUser = (user: string): Address | undefined => {
			const matching = knownUsers.filter((x) => x.name === user)[0];
			if (matching) {
				return matching.address;
			}
		};

		if (ownerInput === "") {
			setOwner(undefined);
			setValidOwnerInput(true);
			return;
		}
		if (utils.isAddress(ownerInput)) {
			setOwner(Address.fromString(ownerInput));
			setValidOwnerInput(true);
			return;
		}
		const address = findAddressForUser(ownerInput);
		if (address) {
			setOwner(address);
			setValidOwnerInput(true);
			return;
		}
		setValidOwnerInput(false);
	}, [ownerInput, setOwner, knownUsers]);

	const [validCollectionInput, setValidCollectionInput] = useState(true);
	const [collection, setCollection] = useState(
		filter.collections ? filter.collections.toString() : "",
	);

	const clearFilter = () => {
		setConfidential(undefined);
		setForSale(undefined);
		setOwner(undefined);
		setCollection("");
		setValidCollectionInput(true);
	};

	const applyFilter = () => {
		setFilter({
			owner: owner,
			collections:
				collection !== ""
					? [Address.fromString(collection)]
					: undefined,
			forSale: forSale,
			confidential: confidential,
		});
		closeDialog();
	};

	const disableConfidential = disable.includes("confidential");
	const disableForSale = disable.includes("forSale");
	const disableOwner = disable.includes("owner");
	const disableCollection = disable.includes("collections");

	const tripleRadioChange = (setter: (x?: boolean) => void) => {
		return (event: React.ChangeEvent<HTMLInputElement>) => {
			switch (event.target.value) {
				case "none":
					setter(false);
					break;
				case "only":
					setter(true);
					break;
				default:
					setter(undefined);
			}
		};
	};

	return (
		<Dialog open={open} maxWidth="md" onClose={closeDialog}>
			<DialogTitle>Filter</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Choose which NFTs to display.
				</DialogContentText>
				<FormLabel component="legend">Confidential tokens</FormLabel>
				<RadioGroup
					row
					aria-label="confidential"
					name="confidential-radio-buttons"
					onChange={tripleRadioChange(setConfidential)}
				>
					<FormControlLabel
						value="none"
						control={<Radio />}
						label="None"
						checked={confidential === false}
						disabled={disableConfidential}
					/>
					<FormControlLabel
						value="all"
						control={<Radio />}
						label="All"
						checked={confidential === undefined}
						disabled={disableConfidential}
					/>
					<FormControlLabel
						value="only"
						control={<Radio />}
						label="Only"
						checked={confidential === true}
						disabled={disableConfidential}
					/>
				</RadioGroup>
				<FormLabel component="legend">Tokens for sale</FormLabel>
				<RadioGroup
					row
					aria-label="for-sale"
					name="for-sale-radio-buttons"
					onChange={tripleRadioChange(setForSale)}
				>
					<FormControlLabel
						value="none"
						control={<Radio />}
						label="None"
						checked={forSale === false}
						disabled={disableForSale}
					/>
					<FormControlLabel
						value="all"
						control={<Radio />}
						label="All"
						checked={forSale === undefined}
						disabled={disableForSale}
					/>
					<FormControlLabel
						value="only"
						control={<Radio />}
						label="Only"
						checked={forSale === true}
						disabled={disableForSale}
					/>
				</RadioGroup>
				<Autocomplete
					id="owner"
					fullWidth
					options={[...knownUsers.map((x) => x.name)]}
					disabled={disableOwner}
					onInputChange={(_, value) => {
						setOwnerInput(value);
					}}
					onChange={(_, value) => {
						setOwnerInput(value || "");
					}}
					value={ownerInput}
					freeSolo
					renderInput={(params) => (
						<TextField
							margin="dense"
							label="Owner"
							variant="standard"
							color="secondary"
							error={!validOwnerInput}
							{...params}
						/>
					)}
				/>

				<TextField
					margin="dense"
					id="owner"
					label="Collection"
					fullWidth
					variant="standard"
					color="secondary"
					onBlur={() => {
						setValidCollectionInput(
							collection === "" || utils.isAddress(collection),
						);
					}}
					onChange={(event) => setCollection(event.target.value)}
					error={!validCollectionInput}
					value={collection}
					disabled={disableCollection}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					color="success"
					variant="outlined"
					onClick={applyFilter}
					disabled={!validCollectionInput || !validOwnerInput}
				>
					save
				</Button>
				<Button
					color="warning"
					variant="outlined"
					onClick={clearFilter}
				>
					reset
				</Button>
			</DialogActions>
		</Dialog>
	);
}
