// SPDX-License-Identifier: Apache-2.0

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
	Button,
} from "@mui/material";

import NFT from "@polycrypt/nerd-marketplace/src/server/nft";
import useBackendContext from "../context/NerdBackendContext";
import useUserContext from "../context/UserContext";
import { useHistory } from "react-router";
import { utils } from "ethers";

interface BuyModalProps {
	nft: NFT;
	open: boolean;
	handleClose: () => void;
}

export default function BuyModal({ nft, open, handleClose }: BuyModalProps) {
	const { getTrade } = useBackendContext();
	const { session } = useUserContext();
	const history = useHistory();

	if (!nft.offer) return <></>; //Todo replace with error modal once made

	const handleBuy = async () => {
		try {
			const tradeOffer = await getTrade(nft.offer!.id);
			if (!tradeOffer) {
				alert("no offer for NFT");
				return;
			}
			await session!.acceptTrade(tradeOffer);
		} catch (e: any) {
			alert("failed to buy NFT");
			console.error(e);
			return;
		}
		history.push("/me/nfts");
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Buy {nft.name} for sale</DialogTitle>
			<DialogContent>
				<List>
					<ListItem>
						<ListItemText>
							<b>Description:</b> {nft.description}
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText>
							<b>Address:</b> {nft.token.toString()}
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText>
							<b>Price (PRN):</b>{" "}
							{utils.formatEther(nft.offer.amount)}
						</ListItemText>
					</ListItem>
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleBuy}>buy</Button>
				<Button onClick={handleClose}>cancel</Button>
			</DialogActions>
		</Dialog>
	);
}
