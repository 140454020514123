// SPDX-License-Identifier: Apache-2.0
import { useEffect, useState } from "react";
import {
	Grid,
	ListItemAvatar,
	AppBar,
	Toolbar,
	IconButton,
	ButtonGroup,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	Drawer,
	useMediaQuery,
	Divider,
	Tooltip,
	Dialog,
} from "@mui/material";

import { Coffee, Menu } from "@mui/icons-material";
import { Address } from "@polycrypt/erdstall/ledger";
import { utils } from "ethers";

import { Link } from "react-router-dom";
import { BalanceProof } from "@polycrypt/erdstall/api/responses";

import OnboardingButton from "../components/OnboardingButton";
import PicsumAvatar from "../components/PicsumAvatar";
import useUserContext from "../context/UserContext";
import { Box, useTheme } from "@mui/system";
import AccountDialog from "../components/AccountDialog";
import { DepositDialog } from "./DepositDialog";
import { retrieveWithdrawableAssets } from "../logic/utils";
import WithdrawSummary from "./WithdrawSummary";

export default function HeaderBar() {
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down("md"));
	const { session, userData, prnBalance } = useUserContext();
	const [showAccountDialog, setShowAccountDialog] = useState(false);
	const [showDepositDialog, setShowDepositDialog] = useState(false);
	return (
		<AppBar position="relative">
			<Box
				sx={{
					flexGrow: 1,
				}}
			>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{
							mr: 2,
						}}
					>
						<Coffee />
					</IconButton>
					{isSmall ? (
						<MobileDrawer />
					) : (
						<Grid
							container
							justifyContent="space-between"
							alignItems="center"
						>
							<Grid item>
								<List
									color="inherit"
									sx={{
										display: "flex",
									}}
								>
									<ListItemLink to="/home" text="Home" />
									<ListItemLink
										to="/me/nfts"
										disabled={!session}
										text="My NFTs"
									/>
									<ListItemLink
										to="/me/mint"
										disabled={!session}
										text="Mint"
									/>
									<ListItemLink
										to={{ pathname: "https://polycry.pt" }}
										target="_blank"
										text="Contact"
									/>
								</List>
							</Grid>
							<Grid item>
								{session ? (
									<List
										sx={{
											display: "flex",
										}}
									>
										<ListItem
											sx={{
												whiteSpace: "nowrap",
											}}
										>
											<ListItemText>
												PRN {prnBalance}
											</ListItemText>
										</ListItem>
										<ListItem
											sx={{
												whiteSpace: "nowrap",
											}}
											onClick={() =>
												setShowAccountDialog(true)
											}
											style={{ cursor: "pointer" }}
										>
											{userData && (
												<ListItemAvatar>
													<PicsumAvatar
														accountPictureId={
															userData.pictureId
														}
													/>
												</ListItemAvatar>
											)}
											<ListItemText>
												{userData ? (
													<b>{userData.name}</b>
												) : (
													<b>Sign Up!</b>
												)}
											</ListItemText>
										</ListItem>
										<AccountDialog
											open={showAccountDialog}
											handleClose={() =>
												setShowAccountDialog(
													(previous) => !previous,
												)
											}
										/>
										<ListItem>
											<ListItemButton
												onClick={() =>
													setShowDepositDialog(true)
												}
											>
												Deposit
											</ListItemButton>
										</ListItem>
										<DepositDialog
											open={showDepositDialog}
											handleClose={() =>
												setShowDepositDialog(false)
											}
										/>
										<ListItem>
											<Withdraw />
										</ListItem>
									</List>
								) : (
									<ButtonGroup sx={{ display: "flex" }}>
										<OnboardingButton />
									</ButtonGroup>
								)}
							</Grid>
						</Grid>
					)}
				</Toolbar>
			</Box>
		</AppBar>
	);
}

function MobileDrawer() {
	const { session, userData, prnBalance } = useUserContext();
	const [showDrawer, setShowDrawer] = useState(false);

	const [showAccountDialog, setShowAccountDialog] = useState(false);
	const [showDepositDialog, setShowDepositDialog] = useState(false);

	const closeDrawer = () => setShowDrawer(false);
	return (
		<>
			<Drawer open={showDrawer} onClose={closeDrawer}>
				<List>
					<ListItemLink
						to="/home"
						text="Home"
						onClick={closeDrawer}
					/>
					<ListItemLink
						to="/me/nfts"
						disabled={!session}
						text="My NFTs"
						onClick={closeDrawer}
					/>
					<ListItemLink
						to="/me/mint"
						disabled={!session}
						text="Mint"
						onClick={closeDrawer}
					/>
					<ListItemLink
						to={{ pathname: "https://polycry.pt" }}
						target="_blank"
						text="Contact"
						onClick={closeDrawer}
					/>
					<Divider />
					{session ? (
						<>
							<ListItem
								sx={{
									whiteSpace: "nowrap",
								}}
							>
								<ListItemText>PRN {prnBalance}</ListItemText>
							</ListItem>
							<ListItem
								sx={{
									whiteSpace: "nowrap",
								}}
								onClick={() => setShowAccountDialog(true)}
								style={{ cursor: "pointer" }}
							>
								{userData && (
									<ListItemAvatar>
										<PicsumAvatar
											accountPictureId={
												userData.pictureId
											}
										/>
									</ListItemAvatar>
								)}
								<ListItemText>
									{userData ? (
										<b>{userData.name}</b>
									) : (
										<b>Sign Up!</b>
									)}
								</ListItemText>
							</ListItem>
							<AccountDialog
								open={showAccountDialog}
								handleClose={() =>
									setShowAccountDialog(
										(previous) => !previous,
									)
								}
							/>
							<ListItem>
								<ListItemButton
									onClick={() => setShowDepositDialog(true)}
								>
									Deposit
								</ListItemButton>
							</ListItem>
							<DepositDialog
								open={showDepositDialog}
								handleClose={() => setShowDepositDialog(false)}
							/>
						</>
					) : (
						<ButtonGroup sx={{ display: "flex" }}>
							<OnboardingButton />
						</ButtonGroup>
					)}
				</List>
			</Drawer>
			<IconButton
				color="inherit"
				onClick={() => setShowDrawer((previous) => !previous)}
			>
				<Menu />
			</IconButton>
		</>
	);
}

function ListItemLink(props: {
	disabled?: boolean;
	to: string | {};
	text: string;
	target?: string;
	onClick?: () => void;
}) {
	return (
		<ListItem
			button
			color="inherit"
			component={Link}
			sx={{
				whiteSpace: "nowrap",
			}}
			{...props}
		>
			<ListItemText color="inherit">{props.text}</ListItemText>
		</ListItem>
	);
}

const MIN_ETH_BAL = utils.parseEther("0.05").toBigInt();

function Withdraw() {
	const { session, provider } = useUserContext();

	const [withdrawing, setWithdrawing] = useState(false);
	const [statusMessage, setStatusMessage] = useState("Withdraw");
	const [withdrawBlocked, setWithdrawBlocked] = useState(true);
	const [assets, setAssets] = useState<[Address, string][]>([]);
	const [showSummary, setShowSummary] = useState<boolean>(false);

	useEffect(() => {
		if (!session || !provider) return;

		const fetchBalance = async () => {
			const bal = (
				await provider.getBalance(session.address.toString())
			).toBigInt();
			if (bal >= MIN_ETH_BAL) {
				setWithdrawBlocked(false);
			}
		};
		fetchBalance();
	}, [provider, session]);

	const withdrawFunds = async (): Promise<[Address, string][]> => {
		let exitProof: BalanceProof;
		session!.once("exitproof", (ep: BalanceProof) => (exitProof = ep));
		const { stages } = await session!.leave(
			(m: string, stage: number, maxStages: number) => {
				setStatusMessage(`${stage}/${maxStages} ` + m);
			},
		);
		for await (const [, tx] of stages) {
			await tx.wait();
		}

		return retrieveWithdrawableAssets(exitProof!.balance.values.values);
	};

	if (assets.length > 0) {
		return (
			<Dialog
				open={showSummary}
				onClose={() => setShowSummary(false)}
				maxWidth="md"
				fullWidth
			>
				<WithdrawSummary assets={assets} />
			</Dialog>
		);
	}
	return (
		<Tooltip
			title={
				withdrawBlocked
					? "Get some ETH before withdrawing your precious NFTs!"
					: ""
			}
		>
			<div>
				<ListItemButton
					sx={{
						whiteSpace: "nowrap",
					}}
					onClick={() => {
						setWithdrawing(true);
						withdrawFunds()
							.then(setAssets)
							.then(() => setShowSummary(true))
							.catch((e) => {
								if (e instanceof Error) {
									alert(e);
								} else {
									alert(JSON.stringify(e));
								}
							})
							.finally(() => {
								setWithdrawing(false);
								setStatusMessage("Withdraw");
							});
					}}
					disabled={!session || withdrawBlocked || withdrawing}
				>
					{statusMessage}
				</ListItemButton>
			</div>
		</Tooltip>
	);
}
