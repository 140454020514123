import { useContext } from "react";

import { Button } from "@mui/material";

import { ethers } from "ethers";

import initWeb3 from "../logic/web3";
import { Address } from "@polycrypt/erdstall/ledger";
import { Session } from "@polycrypt/erdstall";

import { NERDContext } from "../AppContext";
import useUserContext from "../context/UserContext";
import { NetworkID } from "../logic/networks";

export default function OnboardingButton() {
	const { session, setSession, setProvider } = useUserContext();

	const nerdState = useContext(NERDContext);
	const operator = nerdState.operatorURL;
	const config = nerdState.config;

	const executeOnboarding = async () => {
		const networkId = Number(config!.networkID);
		const res = await getAccountProvider(networkId);
		if (!res) return;
		const { account, web3Provider } = res;
		setProvider(web3Provider);
		const address = Address.fromString(account);
		const session = new Session(
			address,
			web3Provider.getSigner(),
			operator,
		);
		session.initialize().then(() => {
			session.subscribeSelf();
			session.onboard();
			setSession(session);
		});
	};

	return (
		<Button
			color="secondary"
			variant="contained"
			disabled={!!session}
			onClick={executeOnboarding}
		>
			Connect to MetaMask
		</Button>
	);
}

async function getAccountProvider(
	networkId: number,
): Promise<
	{ account: string; web3Provider: ethers.providers.Web3Provider } | undefined
> {
	const metamaskErr =
		"Please install MetaMask to enjoy the Nifty-Erdstall experience";
	let web3Provider;
	try {
		web3Provider = await initWeb3();
	} catch (e) {
		if (e instanceof Error) alert([metamaskErr, e.message].join(": "));
		else alert(metamaskErr);
		return;
	}

	const ethereum = web3Provider.provider! as any;

	if (!ethereum.isMetaMask) {
		throw new Error(metamaskErr);
	}

	if (!ethereum.isConnected()) {
		alert(
			"Provider not properly connected to network, check your (blockchain) network settings",
		);
		return;
	}

	const netid = Number(ethereum.chainId);
	if (netid !== networkId) {
		const network = NetworkID.get(networkId);
		const error = `Not connected to correct network, please connect to ${network}`;
		alert(error);
		return;
	}

	let account: string = "";
	try {
		await web3Provider.provider.request!({
			method: "eth_requestAccounts",
		}).then((accs: string[]) => {
			if (accs.length === 0) {
				throw new Error("Please connect to MetaMask");
			}

			account = accs[0];
		});
	} catch (err) {
		alert(err);
	}

	return { account, web3Provider };
}
