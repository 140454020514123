import { useState, useEffect, MouseEvent } from "react";
import { useParams } from "react-router-dom";

import {
	Card,
	CardActions,
	CardContent,
	Container,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Button,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableBody,
	CardHeader,
	Popover,
	Typography,
} from "@mui/material";

import NFT from "@polycrypt/nerd-marketplace/src/server/nft";
import { Address } from "@polycrypt/erdstall/ledger";
import { IERC721Metadata__factory } from "@polycrypt/erdstall/ledger/backend";

import useNerdBackendContext, {
	NerdAccount,
} from "../context/NerdBackendContext";
import NftCard from "../components/NftCard";
import PicsumAvatar from "../components/PicsumAvatar";
import useUserContext from "../context/UserContext";
import SellModal from "../components/SellModal";
import BuyModal from "../components/BuyModal";
import BurnModal from "../components/BurnModal";
import {
	OwnershipEntry,
	PerunArtMetadata,
} from "@polycrypt/nerd-marketplace/src/nft";
import CopyButton from "../components/CopyButton";
import MetadataForm from "../components/MetadataForm";
import { useHistory } from "react-router";

import { CARD_MEDIA_HEIGHT } from "../components/NftCard";
import { PerunArtNFT } from "@polycrypt/nerd-marketplace/src/server/nft";
import { getPicsumUrl } from "../logic/utils";
import { TITLE_LIMIT, DESCRIPTION_LIMIT } from "../components/MetadataForm";

export default function NftDetails() {
	const { token, id } = useParams<{ token: string; id: string }>();

	const {
		getNft,
		getAccount,
		deleteTrade,
		setNft: setNftMetadata,
	} = useNerdBackendContext();
	const { session } = useUserContext();
	const history = useHistory();

	const [nft, setNft] = useState<NFT>();
	const [metadata, setMetadata] = useState<PerunArtMetadata>();

	const [ownerAccount, setOwnerAccount] = useState<NerdAccount>();
	useEffect(() => {
		if (nft) {
			getAccount(nft.owner).then(setOwnerAccount);
		}
	}, [nft, getAccount, setOwnerAccount]);
	const [imageId, setImageId] = useState<number>(0);
	const [isConfidential, setIsConfidential] = useState<boolean>(false);
	const [titleInput, setTitleInput] = useState<string>("");
	const [descriptionInput, setDescriptionInput] = useState<string>("");

	const updateMetadata = async () => {
		if (nft && session) {
			const update = new PerunArtNFT(
				nft.token,
				nft.id,
				nft.owner,
				new PerunArtMetadata(
					titleInput,
					descriptionInput,
					getPicsumUrl(imageId, 512, isConfidential),
					isConfidential,
					{ ts: new Date(), creator: session.address.toString() },
				),
			);

			await new Promise((resolve) => setTimeout(resolve, 500));

			const ok = await setNftMetadata(update);
			if (!ok) {
				alert("failed to update metadata");
				return;
			}

			history.push(`/me/nfts`);
		}
	};

	const updateReady =
		titleInput.length < TITLE_LIMIT &&
		descriptionInput.length < DESCRIPTION_LIMIT;

	const [creatorAccount, setCreatorAccount] = useState<NerdAccount>();
	useEffect(() => {
		if (metadata) {
			if (metadata.history.length === 0) return;
			if (metadata.history[0].owner === undefined) return;
			getAccount(Address.fromString(metadata.history[0].owner)).then(
				setCreatorAccount,
			);
		}
	}, [metadata, getAccount, setCreatorAccount]);

	const [showBuyModal, setShowBuyModal] = useState(false);
	const [showSellModal, setShowSellModal] = useState(false);
	const [showBurnModal, setShowBurnModal] = useState(false);
	const [showHistory, setShowHistory] = useState(false);

	useEffect(() => {
		const idn = BigInt(id);

		getNft(Address.fromString(token), idn)
			.then((nft) => {
				if (nft && nft.metadata) {
					setMetadata(PerunArtMetadata.from(nft.metadata));
				}
				setNft(nft);
			})
			.catch((error) => {
				alert("failed to get nft");
				console.error(error);
			});
	}, [getNft, setNft, showBuyModal, showSellModal, showBurnModal, id, token]);

	if (!nft) {
		return <></>;
	} //Todo replace with new error modal once made

	const cancelTradeOffer = () => {
		deleteTrade(nft.token, nft.id);
		setNft((nft) => {
			if (nft) {
				return NFT.fromObject({ ...nft, offer: undefined });
			}
			return undefined;
		});
	};

	return (
		<Container maxWidth="lg" sx={{ p: 2, flexGrow: 1 }}>
			<Grid
				container
				spacing={1}
				alignItems="stretch"
				justifyContent="center"
			>
				<Grid item md={6}>
					{metadata ? (
						<NftCard nft={nft!} />
					) : (
						<>
							<MetadataForm
								imageId={imageId}
								setImageId={setImageId}
								mediaHeight={CARD_MEDIA_HEIGHT}
								isConfidential={isConfidential}
								setIsConfidential={setIsConfidential}
								titleInput={titleInput}
								setTitleInput={setTitleInput}
								descriptionInput={descriptionInput}
								setDescriptionInput={setDescriptionInput}
							/>
						</>
					)}
				</Grid>
				<Grid item md={6}>
					<Card
						variant="outlined"
						sx={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<CardContent
							sx={{
								flexGrow: 1,
								maxHeight: "450px",
								overflow: "auto",
							}}
						>
							{!showHistory && (
								<List dense>
									<ListItem>
										<ListItemText
											primaryTypographyProps={{
												variant: "h6",
											}}
										>
											Creator
										</ListItemText>
									</ListItem>
									{metadata &&
									metadata.history.length > 0 &&
									metadata.history[0].owner !== undefined ? (
										<AccountListItem
											account={creatorAccount}
											address={metadata.history[0].owner}
										/>
									) : (
										<ListItem>
											<ListItemText>---</ListItemText>
										</ListItem>
									)}
									<ListItem>
										<ListItemText
											primaryTypographyProps={{
												variant: "h6",
											}}
										>
											Owner
										</ListItemText>
									</ListItem>
									<AccountListItem
										account={ownerAccount}
										address={nft.owner.toString()}
									/>
									<ListItem>
										<ListItemText
											primaryTypographyProps={{
												variant: "h6",
											}}
										>
											Contract
										</ListItemText>
									</ListItem>
									<NftTokenListItem nft={nft} />
									<ListItem>
										<ListItemText
											primaryTypographyProps={{
												variant: "h6",
											}}
										>
											NFT-ID
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemText>
											{nft.id.toString()}
											<CopyButton
												text={nft.id.toString()}
											/>
										</ListItemText>
									</ListItem>
								</List>
							)}
							{showHistory &&
								nft.owner &&
								metadata &&
								metadata.history.length > 0 && (
									<HistoryTable history={metadata.history} />
								)}
						</CardContent>
						<CardActions>
							<Grid container direction="column" spacing={1}>
								{metadata && metadata.history && (
									<Grid item>
										<Button
											color="primary"
											variant="text"
											onClick={() =>
												setShowHistory(
													(previous) => !previous,
												)
											}
											fullWidth
										>
											{showHistory
												? "hide history"
												: "show history"}
										</Button>
									</Grid>
								)}
								{nft.offer &&
									session &&
									!nft.owner.equals(session.address) && (
										<Grid item>
											<Button
												color="success"
												onClick={() =>
													setShowBuyModal(true)
												}
												variant="contained"
												fullWidth
											>
												buy
											</Button>
										</Grid>
									)}
								{!nft.offer &&
									session &&
									nft.owner.equals(session.address) && (
										<Grid item>
											<Button
												color="secondary"
												onClick={() =>
													setShowSellModal(true)
												}
												variant="contained"
												fullWidth
											>
												sell
											</Button>
										</Grid>
									)}
								{nft.offer &&
									session &&
									nft.owner.equals(session.address) && (
										<Grid item>
											<Button
												color="primary"
												onClick={cancelTradeOffer}
												variant="contained"
												fullWidth
											>
												cancel trade offer
											</Button>
										</Grid>
									)}
								{session && nft.owner.equals(session.address) && (
									<Grid item>
										<Button
											color="error"
											onClick={() =>
												setShowBurnModal(true)
											}
											variant="contained"
											fullWidth
										>
											burn token
										</Button>
									</Grid>
								)}
								{session && !metadata && (
									<Grid item>
										<Button
											color="warning"
											onClick={() => updateMetadata()}
											variant="contained"
											fullWidth
											disabled={!updateReady}
										>
											update metadata
										</Button>
									</Grid>
								)}
							</Grid>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
			<SellModal
				nft={nft}
				open={showSellModal}
				handleClose={() => setShowSellModal(false)}
			/>
			<BuyModal
				nft={nft}
				open={showBuyModal}
				handleClose={() => setShowBuyModal(false)}
			/>
			<BurnModal
				nft={nft}
				open={showBurnModal}
				handleClose={() => setShowBurnModal(false)}
			/>
		</Container>
	);
}

interface NftTokenListItemProps {
	nft: NFT;
}

function NftTokenListItem({ nft }: NftTokenListItemProps) {
	const { provider } = useUserContext();
	const tokenString = nft.token.toString();
	const [display, setDisplay] = useState<string>(tokenString);
	const [anchor, setAnchor] = useState<Element>();

	const anchorSetter = (
		event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>,
	) => {
		setAnchor(event.currentTarget);
	};

	useEffect(() => {
		if (provider) {
			const erc721md = IERC721Metadata__factory.connect(
				tokenString,
				provider,
			);
			Promise.all([erc721md.name(), erc721md.symbol()])
				.then(([tokenName, tokenSymbol]) => {
					if (tokenName && tokenSymbol) {
						setDisplay(`${tokenName} (${tokenSymbol})`);
					}
				})
				.catch(() => setDisplay(tokenString));
		}
	}, [provider, tokenString]);

	return (
		<ListItem onMouseEnter={anchorSetter} onClick={anchorSetter}>
			<ListItemText sx={{ overflowWrap: "anywhere" }}>
				{display}
			</ListItemText>
			<AddressPopover
				address={tokenString}
				anchor={anchor}
				setAnchor={setAnchor}
			/>
		</ListItem>
	);
}

interface AccountItemProps {
	account?: NerdAccount;
	address: string;
}

function AccountListItem({ account, address }: AccountItemProps) {
	const [anchor, setAnchor] = useState<Element>();

	const anchorSetter = (
		event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>,
	) => {
		setAnchor(event.currentTarget);
	};

	if (account)
		return (
			<ListItem onMouseEnter={anchorSetter} onClick={anchorSetter}>
				<ListItemAvatar>
					<PicsumAvatar accountPictureId={account.profileImageId} />
				</ListItemAvatar>
				<ListItemText>{account.name}</ListItemText>
				<AddressPopover
					address={address}
					anchor={anchor}
					setAnchor={setAnchor}
				/>
			</ListItem>
		);
	else
		return (
			<ListItem>
				<ListItemText sx={{ overflowWrap: "anywhere" }}>
					{address}
					<CopyButton text={address} />
				</ListItemText>
			</ListItem>
		);
}
function AccountTableItem({ account, address }: AccountItemProps) {
	const [anchor, setAnchor] = useState<Element>();

	const anchorSetter = (
		event: MouseEvent<HTMLTableCellElement, globalThis.MouseEvent>,
	) => {
		setAnchor(event.currentTarget);
	};

	if (account)
		return (
			<TableCell onMouseEnter={anchorSetter} onClick={anchorSetter}>
				<CardHeader
					avatar={
						<PicsumAvatar
							accountPictureId={account.profileImageId}
						/>
					}
					title={account.name}
					sx={{ p: 0 }}
				/>
				<AddressPopover
					address={address}
					anchor={anchor}
					setAnchor={setAnchor}
				/>
			</TableCell>
		);
	else
		return (
			<TableCell sx={{ overflowWrap: "anywhere" }}>
				{address} <CopyButton text={address} />
			</TableCell>
		);
}

interface HistoryTableProps {
	history: OwnershipEntry[];
}

function HistoryTable({ history }: HistoryTableProps) {
	const { getAccount } = useNerdBackendContext();
	const [items, setItems] = useState<
		(OwnershipEntry & { account?: NerdAccount })[]
	>([]);
	useEffect(() => {
		const fetchUsers = () => {
			Promise.all(
				history
					.filter((entry) => !!entry.owner)
					.map(async (entry) => {
						return {
							...entry,
							account: await getAccount(
								Address.fromString(entry.owner),
							),
						};
					}),
			).then((items) => setItems(items));
		};
		fetchUsers();
	}, [getAccount, history]);
	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Owner</TableCell>
						<TableCell align="right">Timestamp</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((entry) => {
						return (
							<TableRow key={entry.ts.getTime()}>
								<AccountTableItem
									account={entry.account}
									address={entry.owner}
								/>
								<TableCell align="right">
									{entry.ts.toISOString()}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

interface AddressPopoverProps {
	address: string;
	anchor?: Element;
	setAnchor: (e?: Element) => void;
}

function AddressPopover({ address, anchor, setAnchor }: AddressPopoverProps) {
	return (
		<Popover
			open={!!anchor}
			anchorEl={anchor}
			onClose={() => setAnchor(undefined)}
			anchorOrigin={{
				vertical: "center",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "center",
				horizontal: "left",
			}}
			transitionDuration={0}
		>
			<Grid
				container
				alignContent="space-around"
				alignItems="center"
				onMouseLeave={() => setAnchor(undefined)}
			>
				<Grid item>
					<Typography sx={{ p: 2 }}>{address}</Typography>
				</Grid>
				<Grid item>
					<CopyButton text={address} />
				</Grid>
			</Grid>
		</Popover>
	);
}
