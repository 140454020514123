import { Box } from "@mui/system";
import NftGallery from "../components/NftGallery";
import useUserContext from "../context/UserContext";

export default function MyNfts() {
	const { session } = useUserContext();

	return (
		<Box flexGrow={1}>
			<NftGallery hardFilter={{ owner: session!.address }} />
		</Box>
	);
}
