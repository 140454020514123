import { providers, utils } from "ethers";

import { TradeOffer } from "@polycrypt/erdstall/api/transactions";
import { Address } from "@polycrypt/erdstall/ledger";
import {
	Amount,
	Asset,
	TypeTags,
	Tokens,
	ETHZERO,
} from "@polycrypt/erdstall/ledger/assets";
import { IERC20Metadata__factory } from "@polycrypt/erdstall/ledger/backend/contracts";

import SNFT from "@polycrypt/nerd-marketplace/src/server/nft";

export function nftIsConfidentialTo(
	nft: { owner?: Address; confidential: boolean },
	actor?: Address,
): boolean {
	return nft.confidential && nft.owner ? !actor?.equals(nft.owner) : false;
}

export function nftHasOffer(
	nft: SNFT,
	offers: TradeOffer[],
): TradeOffer | undefined {
	return offers.find((offer) => {
		return (
			offer.offer.hasAsset(nft.token) &&
			(offer.offer.values.get(nft.token.toString())! as Tokens)
				.value[0] === nft.id
		);
	});
}

// ResolveTokenNameAndSymbol uses the given Provider to check whether a
// contract at the given address fullfills generic `name(): string` and/or
// `symbol(): string` methods and returns the corresponding values.
//
// If no `name(): string` is defined, the assumed to be contract address
// parameter will be returned instead.
//
// sdk.ETHZERO is assumed to be Ethereums native token `ETH`.
export async function ResolveTokenNameAndSymbol(
	provider: providers.Provider,
	contract: Address,
): Promise<[string | Address, string | undefined]> {
	if (contract.toString() === ETHZERO) {
		return ["Ether", "ETH"];
	}

	const contractInstance = IERC20Metadata__factory.connect(
		contract.toString(),
		provider,
	);

	let name: string | Address = contract;
	let sym: string | undefined;
	try {
		name = await contractInstance.name();
		sym = await contractInstance.symbol();
	} catch (_) {}

	return [name, sym];
}

export function retrieveWithdrawableAssets(
	values: Map<string, Asset>,
): [Address, string][] {
	const resolveAssetAmount = (a: Asset): string => {
		switch (a.typeTag()) {
			case TypeTags.Amount:
				return utils.formatEther((a as Amount).value);
			case TypeTags.Tokens:
				return (a as Tokens).value.length.toString();
			default:
				throw new Error("unknown asset type");
		}
	};

	const savedAssets: [Address, string][] = [];
	values.forEach((asset, token) => {
		const amount = resolveAssetAmount(asset);
		savedAssets.push([Address.fromString(token), amount]);
	});

	return savedAssets;
}

export async function randomPicsumId(): Promise<number> {
	try {
		let response = await fetch(
			`https://picsum.photos/v2/list?${new URLSearchParams({
				page: Math.floor(Math.random() * 985).toString(),
				limit: "1",
			})}`,
		);
		let data = await response.json();
		return parseInt(data[0]["id"]);
	} catch (e) {
		console.log(`error getting picsum id, ${e}`);
		return 0;
	}
}

export function getPicsumUrl(id: number, size: number, blur?: boolean): string {
	return `https://picsum.photos/id/${id}/${size}/${
		blur ? `?grayscale&blur=9` : ""
	}`;
}
